import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function SearchBar() {
  const navigate = useNavigate();

  function keyPress(e) {
    if (e.code !== "Enter") return;
    const q = e.target.value;
    navigate(`/loads/${q.replace('FA', '')}`);
    e.target.value = "";
  }
  return (
    <div style={styles.container}>
      <input autoFocus={true}
        placeholder="search by FA number"
        onKeyPress={keyPress}
        style={styles.searchInput} />
    </div>
  );
}

const styles = {
  searchInput: {
    flex: 1,
    margin: "auto",
  },
  container: {
    padding: 5,
    maxWidth: 1500,
    display: "flex",
    color: "#D3D3D3",
    backgroundColor: "#dfe6e9",
    margin: "auto",
    textAlign: "center",
  }
};

export default SearchBar;
