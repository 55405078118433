import { Fragment } from "react";
import { Link, useParams  } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Spinner from "../spinner";
import { RequirePlayer } from "../auth";
import {
  useGetCallQuery,
} from "../../services/bulkbookApi";

export default function CallScreen() {
  const { id } = useParams();
  const { data, error, isLoading } = useGetCallQuery(id);

  return (
    <Fragment>
      { error ? <div className="error">Error loading data</div> : null }
      { isLoading ? <Spinner /> : null }
      { !error && data ? <CallDetailTable data={data} /> : null }
    </Fragment>
  )
}

function CallDetailTable({ data }) {
  const created = new Date(data.created).toJSON();
  const call_date = created.split("T")[0];
  const call_time = created.split("T")[1].split(".")[0];

  function Tr({label, data}) {
    return <tr>
      <td>{label}</td>
      <td>{data}</td>
    </tr>
  }

  function Audio({ url }) {
    return <audio controls
      src={url}>
        Your browser does not support the
        <code>audio</code> element.
    </audio>
  }

  return (
    <Table striped hover>
      <tbody>
        <Tr label="Call id" data={data.sid} />
        <Tr label="Call date" data={call_date} />
        <Tr label="Call time" data={call_time} />
        <Tr label="Direction" data={data.direction} />
        <Tr label="Duration" data={data.duration + "s"} />
        <Tr label="Players" data={data.players.join(' > ')} />
        <Tr label="Clients" data={data.clients.join(' ')} />
        {data.recordings.map((r) => <tr>
          <td>Recording</td>
          <td><Audio url={r} /></td>
        </tr>)}
        <Tr label="Status" data={data.status} />
      </tbody>
    </Table>
  )
}
