import { RequirePlayer } from "../auth";
import SearchBar from "../SearchBar";

function LaneTable() {
  return (
    <table>

    </table>
  )
}

export default function LaneScreen() {
  return (
    <RequirePlayer>
      <SearchBar selected="lanes" />
      <h2>lanes</h2>
    </RequirePlayer>
  )
}
