import { useParams, useNavigate } from "react-router-dom";
import { RequirePlayer } from "../auth";
import BackBar from "../BackBar";
import SearchBar from "../SearchBar";
import Spinner from "../spinner";
import ContractDocuments from "./ContractDocuments";
import PaymentBar from "./PaymentBar";
import {
  useGetContractQuery,
} from "../../services/bulkbookApi";

export default function ContractDetailScreen() {
  const { id, image } = useParams();
  const { data, error, isLoading } = useGetContractQuery(id);
  const c = data ? data[0] : null;
  const title = c ? `${c.origin_city || ""} ${c.origin_state || ""} to ` +
    `${c.destination_city || ""} ${c.destination_state || ""}` : null;

  return (
    <RequirePlayer>
      <div>
        <SearchBar />
        <BackBar to="/loads" />
        {error ? <div className="error">Error loading data</div> : null}
        <div style={{ backgroundColor: "white" }}>
          <h2 style={{ margin: 0, padding: 10 }}>{title}&nbsp;&nbsp;(FA{id})</h2>
          {isLoading ? <Spinner /> : null}
        </div>
        { c ? <PaymentBar contract={c} /> : null }
        { c ? <Details contract={c} /> : null }<br/>
        { c ? <ContractDocuments cid={c.id} /> : null }
      </div>
    </RequirePlayer>
  );
}

function DetailsRow({ label, value, badge }) {
  return (
    <tr>
      <td>{label}</td>
      <td>{value} {badge}</td>
    </tr>
  );
}

export function Details({ contract: c }) {
  const fast_pay = c.truck_fast_pay ? <span style={styles.fastPay}>Fast Pay</span> : '';
  const rows = [
    ["Origin company", `${c.origin_company || ""}`],
    ["Origin address", `${c.origin_address || ""}, ${c.origin_address2 || ""}`],
    ["Origin location", `${c.origin_city || ""}, ${c.origin_state || ""}`],
    ["Origin zipcode", `${c.origin_zipcode || ""}`],
    ["Destination company", `${c.destination_company || ""}`],
    ["Destination address", `${c.destination_address || ""}, ${c.destination_address2 || ""}`],
    ["Destination location", `${c.destination_city || ""}, ${c.destination_state || ""}`],
    ["Destination zipcode", `${c.destination_zipcode || ""}`],
    ["Truck", `${c.truck_company || ""}`, fast_pay],
    ["Truck location", `${c.truck_city || ""} ${c.truck_state || ""}`],
    ["Truck address", `${c.truck_address || ""} ${c.truck_address2 || ""} ${c.truck_address3 || ""}`],
    ["Truck phone", `${c.truck_phone || ""}`],
    ["Customer", `${c.customer_company || ""}`],
    ["Customer location", `${c.customer_city || ""} ${c.customer_state || ""}`],
    ["Customer address", `${c.customer_address || ""} ${c.customer_address2 || ""} ${c.customer_address3 || ""}`],
    ["Customer phone", `${c.customer_phone || ""}`],
    ["Load date", `${c.load_date || ""}`],
    ["Delivery date", `${c.delivery_date || ""}`],
    ["Product", `${c.product || ""}`],
    ["Load number", `${c.load_number || ""}`],
    ["Delivery number", `${c.delivery_number || ""}`],
    ["Pay number", `${c.pay_number || ""}`],
    ["Currency", `${c.currency || ""}`],    
    ["Rate", `${c.rate || ""}/${c.rate_type || ""} ${c.fsc || ""}${c.fsc_type || ""}`],
    ["Truck rate", `${c.rate_to_truck || ""}/${c.rate_to_truck_type || ""} ` +
      `${c.fsc_to_truck || ""}${c.fsc_to_truck_type || ""}`],
    ["Shipping status", `${c.shipping_status || ""}`],
    ["Billing status", `${c.status || ""}`],
    ["Billing notes", `${c.notes || ""}`],
  ];

  return (
    <table>
      <tbody className="striped">
        {rows.map((r) => <DetailsRow key={c.id + r[0]} label={r[0]} value={r[1]} badge={r[2]} />)}
      </tbody>
    </table>
  );
}

const styles = {
  fastPay: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ff7675",
    color: "#dfe6e9",
  }
}