import {
  useGetCurrentPlayerQuery,
  useLoginMutation,
  useLogoutMutation,
  useTokenLoginMutation,
} from "../../services/bulkbookApi";

function usePlayer() {
  const { data, error, isLoading } = useGetCurrentPlayerQuery();
  const [updateLogin, { isLoading: loginUpdating, error: loginError }] = useLoginMutation();
  const [updateLogout, { isLoading: logoutUpdating, error: logoutError }] = useLogoutMutation();
  const [tokenLogin, { isLoading: tokenUpdating, error: tokenError }] = useTokenLoginMutation();

  const isUpdating = loginUpdating || logoutUpdating || tokenUpdating;

  return {
    player: data,
    playerError: error,
    playerLoading: isLoading,
    playerUpdating: isUpdating,
    playerLoginError: loginError,
    playerLogoutError: logoutError,
    playerTokenLoginErro: tokenError,
    login: updateLogin,
    logout: updateLogout,
    tokenLogin: tokenLogin,
  };
}

export default usePlayer;
