import { Link, useNavigate } from "react-router-dom";

function BackBar({ to, label }) {
  const navigate = useNavigate();
  label = label ? label : "Back";
  if (to) return (
    <div style={styles.container}>
      <Link to={to}>🡐 {label}</Link>
    </div>
  );
  return (
    <div style={styles.container}>
      <Link to="#" onClick={() => navigate(-1)}>
        <i className="bi bi-arrow-left"></i> {label}
      </Link>
    </div>
  );
}

const styles = {
  searchInput: {
    flex: 1,
    maxWidth: 800,
    margin: "auto",
  },
  container: {
    padding: 5,
    maxWidth: 1500,
    display: "flex",
    color: "#D3D3D3",
    backgroundColor: "#dfe6e9",
    margin: "auto",
    textAlign: "center",
    borderTop: "1px solid #D3D3D3",
  }
};

export default BackBar;
