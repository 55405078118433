import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const accountId = "edgsOVLLonMQi4a5Ix9OvQ"

export const imagesApi = createApi({
  reducerPath: "bulkbookApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://upload.imagedelivery.net/" }),
  tagTypes: [],
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: ({ id, form }) => ({
        url: `/${accountId}/${id}`,
        method: "POST",
        body: form,
      }),
    }),
  }),
});

export const { useUploadImageMutation } = imagesApi;
