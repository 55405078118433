import { Fragment } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { RequirePlayer } from "../auth";
import Spinner from "../spinner";
import BackBar from "../BackBar";
import {
  useGetTasksQuery,
} from "../../services/bulkbookApi";

function TaskTableRow({ data }) {
  const TableData = ({ td }) => <td><Link to="#">{td}</Link></td>;
  const today = new Date();
  const today_date = today.toJSON().split("T")[0]
  let date = data.created ? new Date(data.created) : null;
  date = date ? date.toJSON().replace("T", " ")
    .replace(today_date, "")
    .replace(`${today.getFullYear()}-`, "").split(".")[0] : null;

  return (
    <tr>
      <TableData td={data.player} />
      <TableData td={data.client_name} />
      <TableData td={data.direction} />
      <TableData td={data.type} />
      <TableData td={data.status} />
      <TableData td={date} />
    </tr>
  );
}

function TaskTable({ rows }) {
  return (
    <table>
      <tbody className="striped">
        {rows ? rows.map((r) => {
          return <TaskTableRow data={r} key={r.sid} />
        }) : null}
      </tbody>
    </table>
  );
}

export default function TaskScreen({ filter }) {
  const { data, error, isLoading } = useGetTasksQuery(null, {pollingInterval: 3000});
  return (
    <Fragment>
      <BackBar />
      {error ? <div className="error">Error loading data</div> : null}
      {isLoading ? <Spinner /> : null}
      {data ? <TaskTable rows={data.tasks} /> : null}
      <br/>
      <br/>
    </Fragment>
  );
}
