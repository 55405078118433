import { configureStore } from "@reduxjs/toolkit";
import { bulkbookApi } from "../services/bulkbookApi";
import { bulkbookClientApi } from "../services/bulkbookClientApi";

export const store = configureStore({
  reducer: {
    [bulkbookApi.reducerPath]: bulkbookApi.reducer,
    [bulkbookClientApi.reducerPath]: bulkbookClientApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bulkbookApi.middleware)
});
