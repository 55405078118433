import { Fragment, useState } from "react";
import { useUploadImageMutation } from "../services/imagesApi";
import {
  useCreateUploadTokenMutation,
} from "../services/bulkbookClientApi";
import {
  useSaveDocumentMutation,
} from "../services/bulkbookApi";

export default function DocUploader({ contactId, contractId, companyId }) {
  const [ warning, setWarning ] = useState();
  const [saveDocument, {
    error: documentError,
    isLoading: documentLoading,
  }] = useSaveDocumentMutation();
  const [createToken, {
    error: tokenError,
    isLoading: tokenLoading,
  }] = useCreateUploadTokenMutation();
  const [uploadImage, {
    error: imageError,
    isLoading: imageLoading
  }] = useUploadImageMutation();

  const error = documentError || tokenError || imageError;

  const processFile = async (file) => {
    const { data } = await createToken();
    const id = data.result.id;
    const form = new FormData();
    form.append("file", file, file.name);
    await uploadImage({ id, form });
    await saveDocument({ imageId: id, contractId, contactId, companyId });
  };

  return (
    <Fragment>
      {tokenLoading ? <div className="">Loading...</div> : null }
      {imageLoading ? <div className="">Saving...</div> : null }
      {documentLoading ? <div className="">Attaching...</div> : null }
      {error ? <div className="error">Error saving</div> : null }
      {warning ? <div className="error">{warning}</div> : null }
      <div onDrop={handleDrop} onDragOver={handleOver} style={styles.container}>
        <div style={styles.label}>
          drag and drop here to upload documents
        </div>
      </div>
    </Fragment>
  );

  function handleDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 1) {
        setWarning("one at a time, please");
        return;
      }
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind !== "file") {
          setWarning("give me a file, please");
          return;
        }
        const file = e.dataTransfer.items[i].getAsFile();
        if (!file.name.toLowerCase().match(/\.jpg$|\.jpeg$/)) {
          setWarning("give me a jpg, please");
          return;
        }
        processFile(file);
      }
    } else {
      if (e.dataTransfer.files.length > 1) {
        setWarning("one at a time, please");
        return;
      }
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (!file.name.toLowerCase().match(/\.jpg$|\.jpeg$/)) {
          setWarning("give me a jpg, please");
          return;
        }
        processFile(file);
      }
    }
  }
  function handleOver(e) {
    e.preventDefault();
  }
}

const styles = {
  container: {
    display: "flex",
    backgroundColor: "white",
    borderStyle: "solid",
    borderSize: 1,
    minHeight: 100,
    minWidth: 200,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#dfe6e9",
    color: "#b2bec3",
  },
};
