import DocUploader from "../DocUploader";
import Spinner from '../spinner';
import {
  useGetContractDocumentsQuery,
} from "../../services/bulkbookApi";

export default function ContractDocuments({ cid }) {
  const { data, error, isLoading } = useGetContractDocumentsQuery(cid);

  return (
    <div>
      <DocUploader contractId={cid} />
  
      <div style={{
        display: "flex",
        padding: 10,
        marginTop: 10,
        minHeight: 20,
        backgroundColor: "white"}}>
        {error ? <div className="error">Error loading data</div> : null}
        { isLoading ? <Spinner size="20" /> : null }
        { data ? data.map((d) => 
          <ContractDoc key={d.image_id} imageId={d.image_id} />) : null }
      </div>
    </div>
  );
}

function ContractDoc({ imageId }) {
  const root = "https://imagedelivery.net/edgsOVLLonMQi4a5Ix9OvQ"
  const url = `${root}/${imageId}/public`;

  return (
    <div style={{margin: 10}}>
      <a href={url} target="_blank">
        <img height="200" src={url} style={{backgroundColor: "#D3D3D3" }}/>
      </a>
    </div>
  );
}

function ContractDocFull({ imageId }) {
  const root = "https://imagedelivery.net/edgsOVLLonMQi4a5Ix9OvQ"
  const url = `${root}/${imageId}/public`;
  const style = {
    padding: 10,
    backgroundColor: "#D3D3D3",
  };
  return (
    <div style={{margin: 10}} className="page-break">
      <a href={url} target="_blank">
        <img width="100%" src={url} style={style} className="page-break" />
      </a>
    </div>
  );
}

export function ContractDocumentsFull({ cid }) {
  const { data, error, isLoading } = useGetContractDocumentsQuery(cid);

  return (
    <div>
        <div className="fullsize-image-container" style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        marginTop: 10,
        minHeight: 20,
        backgroundColor: "white"}}>
        {error ? <div className="error">Error loading data</div> : null}
        { isLoading ? <Spinner size="20" /> : null }
        { data ? data.map((d) => 
          <ContractDocFull key={d.image_id} imageId={d.image_id} />) : null }
      </div>
    </div>
  );
}
