import { Link, useSearchParams } from "react-router-dom";
import { RequirePlayer } from "../auth";
import SearchBar from "../SearchBar";
import Spinner from "../spinner";
import {
  useGetOpenContractsQuery,
  useGetOpenContractsWithDocsQuery,
} from "../../services/bulkbookApi";

function ContractsTableRow({ data, dateFormatter }) {
  const TableData = ({ td }) => <td><Link to={"/loads/" + data.id}>{td}</Link></td>;
  const date = data.delivery_date.split("T")[0].replace(dateFormatter, "").replaceAll("-", "‑");

  const styles = {
    fastPayRow: {
      borderLeft: '2px solid #ff7675',
    },
  }
  return (
    <tr style={ data.truck_fast_pay ? styles.fastPayRow : null }>
      <TableData td={data.id} />
      <TableData td={data.origin_city + " " + data.origin_state} />
      <TableData td={data.destination_city + " " + data.destination_state} />
      <TableData td={date} />
    </tr>
  );
}

function ContractTable({ rows }) {
  const re = new RegExp(`${new Date().getFullYear()}-`);
  return (
    <table>
      <tbody className="striped">
        {rows ? rows.map((r) => {
          return <ContractsTableRow data={r} key={r.id} dateFormatter={re} />
        }) : null}
      </tbody>
    </table>
  );
}

export default function ContractScreen({ filter }) {
  if (filter === "aging" ) return <OpenContracts />
  return <OpenContractsWithDocs />
}

function OpenContracts() {
  const { data, error, isLoading } = useGetOpenContractsQuery();
  return (
    <RequirePlayer>
      <SearchBar />
      {error ? <div className="error">Error loading data</div> : null}
      {isLoading ? <Spinner /> : null}
      <ContractTable rows={data} />
      <br/>
      <br/>
    </RequirePlayer>
  );
}

function OpenContractsWithDocs() {
  const { data, error, isLoading } = useGetOpenContractsWithDocsQuery();
  return (
    <RequirePlayer>
      <SearchBar selected="contracts" />
      {error ? <div className="error">Error loading data</div> : null}
      {isLoading ? <Spinner /> : ""}
      <ContractTable rows={data} />
      <br/>
      <br/>
    </RequirePlayer>
  );
}
