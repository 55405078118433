import { useState } from "react";
import { Link } from "react-router-dom";
import { RequirePlayer } from "../auth";
import Spinner from "../spinner";
import {
  useGetWeeklyStatsQuery,
} from "../../services/bulkbookApi";

export default function StatsScreen() {
  const { data, error, isLoading } = useGetWeeklyStatsQuery();
  const { show, setShow } = useState({});

  // const players = data ? [... new Set(Object.keys(data).flatMap((d) => {
  //   return Object.keys(data[d]);
  // }))] : null;

  const players = {};
  const teams = {};
  if(data) {
    Object.keys(data).forEach((d) => {
      Object.keys(data[d]).forEach((nick) => {
        if(!players[nick]) players[nick] = {};
        if(!players[nick].days) players[nick].days = {};
        players[nick].days[d] = data[d][nick];
      })
    });

    Object.keys(players).forEach((p) => {
      Object.keys(players[p].days).forEach((day) => {
        Object.keys(players[p].days[day]).forEach((col) => {
          const team = players[p].days[day].player.team_id;
          if(!team) throw new Error(`Team not found for player: ${p}`);

          if(!teams[team]) teams[team] = {};
          if(!teams[team].totals) teams[team].totals = {};
          if(!teams[team].totals[col]) teams[team].totals[col] = 0;
          teams[team].totals[col] += players[p].days[day][col];

          if(!players[p].totals) players[p].totals = {};
          if(!players[p].totals[col]) players[p].totals[col] = 0;
          players[p].totals[col] += players[p].days[day][col];
        });
      })
    });
  }

  const sortedTeams = Object.keys(teams).length ? 
    Object.keys(teams).sort((a,b) => {
      return teams[a].totals.assigned > teams[b].totals.assigned ? -1 : 1;
    }) : null;

  const sortedPlayers = Object.keys(players).length ? 
    Object.keys(players).sort((a,b) => {
      return players[a].totals.assigned > players[b].totals.assigned ? -1 : 1;
    }) : null;

  return (
    <RequirePlayer>
      <div styles={styles.container}>
        {error ? <div className="error">Error loading data</div> : null}
        {isLoading ? <Spinner /> : null}
        {sortedTeams ? sortedTeams.map((p) => 
          <TeamCard key={p} team={`Team ${p}`} stats={teams[p]} />) : null}
        <br/><br/>
        {sortedPlayers ? sortedPlayers.map((p) => 
          <PlayerCard key={p} nick={p} stats={players[p]} />) : null}
      </div>
    </RequirePlayer>
  )
}

function TeamCard({ team, stats }) {
  if(team !== "Team 6" && team !== "Team 9") return null;
  if(team === "Team 6") team = "The Mighty Ducks";
  if(team === "Team 9") team = "The Crazy Coyotes";

  const headers = ["comments",	"commands",	"calls",	"in_calls",	"out_calls",
    "calls_time",	"assigned",	"delivered"];

    return (
    <div style={{paddingBottom: 10}}>
      <table>
        <tbody className="striped">
          <tr key={team+"h"}>
            <th key={team+"hteam"}>{team}</th>
            {headers.map((h) => <th key={team+"h"+h}>{h}</th>)}
          </tr>
          <PlayerCardRow style={{borderTop: "1px solid black"}} day="total"
            data={stats.totals} key={team+"total"} headers={headers} />
        </tbody>
      </table>
    </div>
  )
}

function PlayerCard({ nick, stats }) {
  const headers = ["comments",	"commands",	"calls",	"in_calls",	"out_calls",
    "calls_time",	"assigned",	"delivered"];

    return (
    <div style={{paddingBottom: 10}}>
      <table>
        <tbody className="striped">
          <tr key={nick+"h"}>
            <th key={nick+"hnick"}>{nick}</th>
            {headers.map((h) => <th key={nick+"h"+h}>{h}</th>)}
          </tr>
          {Object.keys(stats.days).map((d) =>
            <PlayerCardRow day={d} data={stats.days[d]} key={nick+d} headers={headers} />)}
          <PlayerCardRow style={{borderTop: "1px solid black"}} day="total"
            data={stats.totals} key={nick+"total"} headers={headers} />
        </tbody>
      </table>
    </div>
  )
}

function PlayerCardRow({ day, data, headers, style }) {
  style = {...style, textAlign: "center"}
  return (
    <tr style={style}>
      <td key="day">{day}</td>
      {headers.map((h) => <td key={h} title={h}>{data[h] || ""}</td>)}
    </tr>
  )
}

const styles = {
  container: {
    padding: 10,
  }
}
