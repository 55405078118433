import { Fragment } from "react";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Spinner from "../spinner";
import BackBar from "../BackBar";
import { ContactDocuments, ContactDocumentModal } from "./ContactDocuments";
import { CompanyComments, CreateContactComment } from "./ContactComments";
import usePlayer from "../auth/usePlayer";
import {
  useGetContactQuery,
  useCreateSmsThreadMutation,
} from "../../services/bulkbookApi";

export default function ContactDetailScreen() {
  const { id, documentId } = useParams();
  const { data, error, isLoading } = useGetContactQuery(id);

  return (
    <Fragment>
      <BackBar />
      { documentId ? 
        <ContactDocumentModal contactId={id} documentId={documentId} /> : null}
      <Container fluid className="pt-1">
        <Row>
          <Col>
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                {error ? <div className="error">Error loading data</div> : null}
                {isLoading ? <Spinner size="25" /> : null}
                {data ? <ContactBody data={data[0]} /> : null}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Documents</Card.Header>
              <Card.Body >
                {error ? <div className="error">Error loading data</div> : null}
                {isLoading ? <Spinner size="25" /> : null}
                {data ? <ContactDocuments cid={data[0].id} mid={data[0].company_id} /> : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="pt-2">
          <Card className="pt-3">
            {data ? <CreateContactComment cid={data[0].id} /> : null}
          </Card>
        </Row>
        <Row className="pt-2">
          {data ? <CompanyComments mid={data[0].company_id} /> : null}
        </Row>
      </Container>
    </Fragment>
  )
}

function PhoneField({num}){
  const { player } = usePlayer();

  const [createSms, {
    error,
    isLoading,
  }] = useCreateSmsThreadMutation();
  if(!num) return null;

  return (
    <div>
      {num}
      <Button onClick={() => createSms({num, worker_id: player.twilio_worker_id})}
        variant="secondary" size="sm">sms</Button>
      {isLoading ? <Spinner size="10" /> : null}
      {error ? <div className="error">Error creating SMS thread</div> : null }
    </div>
  )
}

function ContactBody({ data }) {
  return (
    <Fragment>
      {data.first} {data.last}<br/>
      <strong>{data.company_name}</strong>
      {data.fast_pay ? <Badge pill>Fast pay</Badge> : null}<br/>
      {data.street ? <div>{data.street}</div> : null}
      {data.street2 ? <div>{data.street2}</div> : null}
      {data.street3 ? <div>{data.street3}</div> : null}
      <div>{data.city} {data.state}</div>
      <PhoneField num={data.phone} />
      <PhoneField num={data.phone1} />
      <PhoneField num={data.phone2} />
      <PhoneField num={data.phone3} />
      <PhoneField num={data.phone4} />
      <PhoneField num={data.phone5} />
      <PhoneField num={data.phone6} />
      <PhoneField num={data.phone7} />
      <PhoneField num={data.phone8} />
      <PhoneField num={data.phone9} />
      <PhoneField num={data.phone0} />
      {data.email ? <div>{data.email}</div> : null}
      {data.notes ? <div>{data.notes}</div> : null}
    </Fragment>
  )
}
