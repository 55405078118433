import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { HideIfNoPlayer } from "../auth";
import Spinner from "../spinner";
import {
  useGetTasksQuery,
} from "../../services/bulkbookApi";


const itemStyle = {
  display: 'inline-block',
  textAlign: 'center',
  borderStyle: 'solid',
  borderWidth: 1,
  width: 30,
  height: 30,
  margin: 5,
  cursor: 'pointer',
}
const detailStyle = {
  position: 'absolute',
  backgroundColor: 'grey',
}

function TaskBarItem({data}) {
  const navigate = useNavigate();
  function click() {
    navigate('/tasks');
  }
  if (data.status !== "assigned") return null;
  return(
    <HideIfNoPlayer>
      <div title={data.player + ' ' + data.client_name} style={itemStyle} onClick={click}>
        {data.player.charAt(0).toUpperCase()}
      </div>
    </HideIfNoPlayer>
  )
}

export default function TaskBar() {
  const { data, error, isLoading } = useGetTasksQuery(null, {pollingInterval: 3000});
  return (
    <Fragment>
      {error ? <div className="error">Error loading data</div> : null}
      {isLoading ? <Spinner size={15} /> : null}
      {data ? data.tasks.map((d) => <TaskBarItem data={d} key={d.sid} />) : null}
    </Fragment>
  );
}
