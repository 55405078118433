import React from "react";
import { Fragment, useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import PaymentRequestScreen from "./components/PaymentRequestScreen";
import { ContractScreen, ContractDetailScreen } from "./components/contract";
import { ContactScreen, ContactDetailScreen } from "./components/contact";
import { CallScreen, CallDetailScreen } from "./components/call";
import { TaskScreen } from "./components/task";
import { StatsScreen } from "./components/stats";
import { LaneScreen } from "./components/lane";
import { usePlayer } from "./components/auth";
import NavBar from "./components/NavBar";
import { Provider, ErrorBoundary } from "@rollbar/react";


const rollbarConfig = {
  accessToken: '93a3b3e4fb1d4ec39c6497fd86639fc5',
  environment: 'production',
  server: {
    root: "https://bulkbook.com/",
    branch: "main",
  },
  code_version: "0.1.0",
  person: {
    id: 1,
    email: "nick@nickaugust.com",
    username: "nick",
  }
}

function NotFound() {
  return (
    <div style={styles.notFound}>
      <h1>404 Not found</h1>
    </div>
  );
}

function App() {
  const { tokenLogin } = usePlayer();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if(token) {
      setSearchParams({});
      tokenLogin(token);
    }
  })

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Fragment>
          <div className="side"></div>
          <div className="center">
            <NavBar />
            <Routes>
              <Route path="/" element={<Navigate to="/loads" />} />
              <Route path="/payreq/:id" element={<PaymentRequestScreen />} />
              <Route path="/loads" element={<ContractScreen />} />
              <Route path="/stats" element={<StatsScreen />} />
              <Route path="/aging" element={<ContractScreen filter="aging" />} />
              <Route path="/loads/:id" element={<ContractDetailScreen />} />
              <Route path="/loads/:id/docs/:image/:action" 
                element={<ContractDetailScreen />} />
              <Route path="/calls" element={<CallScreen />} />
              <Route path="/calls/:id" element={<CallDetailScreen />} />
              <Route path="/tasks" element={<TaskScreen />} />
              <Route path="/lanes" element={<LaneScreen />} />
              <Route path="/lanes/:filter" element={<LaneScreen />} />
              <Route path="/contacts" element={<ContactScreen />} />
              <Route path="/contacts/:id" element={<ContactDetailScreen />} />
              <Route path="/contacts/:id/docs/:documentId"
                element={<ContactDetailScreen />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <div className="side"></div>
        </Fragment>
      </ErrorBoundary>
    </Provider>
  );
}

const styles = {
  notFound: {
    padding: 20,
    backgroundColor: "#dfe6e9",
  }
};

export default App;
