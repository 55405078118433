import { Fragment } from "react";
import { Link, useSearchParams  } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Spinner from "../spinner";
import { RequirePlayer } from "../auth";
import {
  useGetCallListQuery,
} from "../../services/bulkbookApi";

export default function CallScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q', '');
  const { data, error, isLoading } = useGetCallListQuery(q, {pollingInterval: 10000});

  return (
    <Fragment>
      { q ? <div style={{padding: 5, backgroundColor: 'white'}}>
        <strong>searching for:</strong> {q}
        <small> <Link to="/calls">clear</Link></small></div>
        : null }
      { error ? <div className="error">Error loading data</div> : null }
      { isLoading ? <Spinner /> : null }
      { !error && data && data.calls ? <CallsTable rows={data.calls} /> : null }
    </Fragment>
  )
}

function CallsTable({ rows }) {
  return (
    <Table striped hover>
      <tbody>
        {rows.map((r) => <CallsTableRow data={r} key={r.id} />)}
      </tbody>
    </Table>
  )
}

function CallsTableRow({ data }) {
  const Td = ({id, title, children}) => <td title={title} key={title+id}>
    <Link to={'/calls/' + id}>{children}</Link>
  </td>;
  const created = new Date(data.created).toJSON();
  const call_date = created.split("T")[0].replaceAll("-", "‑");
  const call_time = created.split("T")[1].split(".")[0];

  return (
    <tr>
      <Td title="Created date" id={data.sid}>{call_date}</Td>
      <Td title="Created time" id={data.sid}>{call_time}</Td>
      <Td title="Direction" id={data.sid}>{data.direction}</Td>
      <Td title="Duration" id={data.sid}>{data.duration + "s"}</Td>
      <Td title="Players" id={data.sid}>{data.players.join(" > ")}</Td>
      <Td title="Clients" id={data.sid}>{data.clients.join(" ")}</Td>
      <Td title="Status" id={data.sid}>{data.status}</Td>
    </tr>
  )
}
