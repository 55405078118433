import { Fragment } from "react";
//import { Navigate, useLocation } from "react-router-dom";

import Login from "./Login";
import usePlayer from "./usePlayer";
import Spinner from "../spinner";


function RequirePlayerLogin({ login, playerLoginError, playerUpdating }) {
  return (
    <Fragment>
      <div className="bg-img" style={{minHeight: 500}}></div>
      <div style={{
        maxWidth: 300,
        top: 10,
        margin: 10,
        position: "fixed"}}>
        <Login
          login={login}
          playerLoginError={playerLoginError}
          playerUpdating={playerUpdating}
        />
        <br/><br/>
      </div>
    </Fragment>
  );
}

const canAuthenticate = true;

function HideIfNoPlayer({ children }) {
  const { player, playerError, playerLoading } = usePlayer();

  if (!canAuthenticate) return children;

  if (playerLoading) return null;
  if (playerError) return null;
  return player != null && player.email != null ? children : null;
}

function RequirePlayer({ children }) {
  const { player, playerError, playerLoading, playerUpdating, playerLoginError, login } =
    usePlayer();
  //   const location = useLocation();

  if (!canAuthenticate) return children;

  if (playerLoading) return <Spinner />;

  // Player is not authenticated
  if (playerError && playerError.status === 401) {
    return (
      <RequirePlayerLogin
        login={login}
        playerLoginError={playerLoginError}
        playerUpdating={playerUpdating}
      />
    );
  }

  // return (player != null && player.email != null)
  //   ? children
  //   : <Navigate to="/login" replace state={location.pathname} />;

  // Just show the login page if not logged in
  return player != null && player.email != null ? (
    children
  ) : (
    <RequirePlayerLogin
      login={login}
      playerLoginError={playerLoginError}
      playerUpdating={playerUpdating}
    />
  );
}

export default RequirePlayer;
export { HideIfNoPlayer, usePlayer };
