import { useState } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/esm/Container";
import Spinner from '../spinner';
import {
  useGetCompanyCommentsQuery,
  useCreateContactCommentMutation,
} from "../../services/bulkbookApi";


export function CreateContactComment({ cid }) {
  const [comment, setComment] = useState('');
  const [
    createComment,
    {
      isLoading,
      error,
      isSuccess,
      reset,
    },
  ] = useCreateContactCommentMutation();

  const submit = (e) => {
    e.preventDefault();
    createComment({contact_id: cid, body: comment});
    setComment('');
  }

  return (
    <Container>
      {error ? <div className="error">Error loading data</div> : null}
      { isLoading ? <Spinner size="20" /> : null }
      <form onSubmit={submit}>
        <InputGroup className="mb-3">
          <FormControl value={comment} onChange={(e) => setComment(e.target.value)} />
          <Button variant="outline-secondary" type="submit">
            Save comment
          </Button>
        </InputGroup>
      </form>
    </Container>
  );
}

export function ContactComments({ cid }) {
  const { data, error, isLoading } = useGetCompanyCommentsQuery(cid);

  return (
    <ListGroup>
      {error ? <div className="error">Error loading data</div> : null}
      { isLoading ? <Spinner size="20" /> : null }
      { data ? data.map((d) => {
        const year = new Date().getFullYear();
        return <ListGroup.Item as="li" key={d.id}>
          <div className="d-inline pe-2 fs-6 fw-light">
            <small>{d.contact_first} {d.contact_last}</small>
          </div>
          <div className="d-inline pe-2">
            {d.body}
          </div>
          <div className="d-inline float-end fs-6 fw-light">
            {d.nick}<small> {d.created.split('T')[0].replace(`${year}-`, '')}</small>
          </div>
        </ListGroup.Item>}) : null }
      <br/>
    </ListGroup>
  );
}

export function CompanyComments({ mid }) {
  const { data, error, isLoading } = useGetCompanyCommentsQuery(mid);

  return (
    <ListGroup>
      {error ? <div className="error">Error loading data</div> : null}
      { isLoading ? <Spinner size="20" /> : null }
      { data ? data.map((d) => {
        const year = new Date().getFullYear();
        return <ListGroup.Item as="li" key={d.id}>
          <div className="d-inline pe-2 fs-6 fw-light">
            <small>{d.contact_first} {d.contact_last}</small>
          </div>
          <div className="d-inline pe-2">
            {d.body}
          </div>
          <div className="d-inline float-end fs-6 fw-light">
            {d.nick}<small> {d.created.split('T')[0].replace(`${year}-`, '')}</small>
          </div>
        </ListGroup.Item>}) : null }
      <br/>
    </ListGroup>
  );
}
