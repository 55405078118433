import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { RequirePlayer } from "./auth";
import { useGetContractQuery } from "../services/bulkbookApi";
import { ContractDocumentsFull, Details } from "./contract";
import Spinner from "./spinner";

export default function PaymentRequestScreen() {
  const { id } = useParams();
  const { data, error, isLoading } = useGetContractQuery(id);
  const c = data ? data[0] : null;
  const title = c ? `${c.origin_city || ""} ${c.origin_state || ""} to ` +
    `${c.destination_city || ""} ${c.destination_state || ""}` : null;

  return (
    <Fragment>
      {error ? <div className="error">Error loading data</div> : null}
      <div style={{ backgroundColor: "white" }}>
        <h2 style={{ margin: 0, padding: 5 }}>{title}&nbsp;&nbsp;(FA{id})</h2>
        {isLoading ? <Spinner /> : null}
      </div>
      { c ? <Details contract={c} /> : null }<br/>
      { c ? <ContractDocumentsFull cid={c.id} /> : null }
    </Fragment>
  )
}
