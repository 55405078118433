import React from "react";
import Spinner from "../spinner";

function Login({ login, playerLoginError, playerUpdating }) {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ email, password });
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit}>
        <div style={styles.warning} className="fade-out">
          {playerLoginError ? "Incorrect email or password" : null}
        </div>
        {playerUpdating ? (
          <Spinner size={20} variant="light" message="Logging in..." />
          ) : ""}
        <div style={styles.row}>
          <input
            autoFocus={true}
            required={true}
            type="email"
            style={styles.emailInput}
            placeholder="Email address"
            readOnly={playerUpdating}
            onChange={(e) => setEmail(e.target.value.trim())}
            />
        </div>
        <div style={styles.row}>
          <input
            required={true}
            style={styles.passwordInput}
            type="password"
            placeholder="Password"
            readOnly={playerUpdating}
            onChange={(e) => setPassword(e.target.value.trim())}
            />
        </div>
        <div style={styles.row}>
          <button style={styles.btn} type="submit" disabled={playerUpdating}>Login</button>
        </div>
      </form>
    </div>
  );
}

const styles = {
  emailInput: {
    flex: 1,
    textAlign: "center",     
  },
  passwordInput: {
    flex: 1,
    textAlign: "center",
    borderTop: "1px solid black",
  },
  warning: {
    height: 20,
    textAlign: "center",
  },
  row: {
    display: "flex",
  },
  btn: {
    flex: 1,
    marginTop: 10,
    padding: "5px 40px",
    color: "#636e72",
    borderColor: "#636e72",
    backgroundColor: "#55efc4",
  },
  container: {
    width: 200,
    height: 200,
    paddingTop: 30,
  }
};
export default Login;
