import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bulkbookClientApi = createApi({
  reducerPath: "bulkbookClientApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://bulkbook.com/api/" }),
  tagTypes: [
    "Documents",
  ],
  endpoints: (builder) => ({
    createUploadToken: builder.mutation({
      query: () => {
        return {
          url: "documents/upload",
          method: "POST",
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useCreateUploadTokenMutation,
} = bulkbookClientApi;
