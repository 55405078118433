import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bulkbookApi = createApi({
  reducerPath: "bulkbookApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.bulkbook.com/" }),
  tagTypes: [
    "Player",
    "Contract",
    "Contracts",
    "Documents",
    "WeeklyStats", 
    "Contacts",
    "Tasks",
  ],
  endpoints: (builder) => ({
    getCall: builder.query({
      query: (id) => {
        return {
          url: `calls/${id}`,
          credentials: "include",
        };
      },
      providesTags: (resp) => {
         return [{ type: "Call" }];
      },
    }),
    getCallList: builder.query({
      query: (q) => {
        return {
          url: q ? `calls?q=${encodeURIComponent(q)}` : "calls",
          credentials: "include",
        };
      },
      providesTags: (resp) => {
         return [{ type: "Calls" }];
      },
    }),
    getTasks: builder.query({
      query: (q) => {
        return {
          url: q ? `tasks?q=${encodeURIComponent(q)}` : "tasks",
          credentials: "include",
        };
      },
      providesTags: (resp) => {
         if (!resp && !resp.tasks) return;
         return [{ type: "Tasks" }];
      },
    }),
    getContacts: builder.query({
      query: (q) => {
        return {
          url: `contacts?q=${encodeURIComponent(q)}`,
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "Contacts" }];
      },
    }),
    getContact: builder.query({
      query: (id) => {
        return {
          url: `contacts/${id}`,
          credentials: "include",
        };
      },
      providesTags: (contact) => {
        const id = contact ? contact.id : null;
        return [{ type: "Contacts", id }];
      },
    }),
    createSmsThread: builder.mutation({
      query: (json) => {
        return {
          url: "sms",
          method: "POST",
          credentials: "include",
          body: json,
        };
      },
      invalidatesTags: [{ type: 'SmsHistory' }],
    }),
    getWeeklyStats: builder.query({
      query: () => {
        return {
          url: "stats/weekly",
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "WeeklyStats" }];
      },
    }),
    getContract: builder.query({
      query: (id) => {
        return {
          url: `contracts/${id}`,
          credentials: "include",
        };
      },
      providesTags: (contracts) => {
        const id = contracts && contracts.length ? contracts[0].id : null;
        return [{ type: "Contract", id }];
      },
    }),
    getOpenContracts: builder.query({
      query: () => {
        return {
          url: "contracts?filter=open&order=delivery_date",
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "Contracts" }];
      },
    }),
    getOpenContractsWithDocs: builder.query({
      query: () => {
        return {
          url: "contracts?filter=documents",
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "Contracts" }];
      },
    }),
    createContactComment: builder.mutation({
      query: (json) => {
        return {
          url: `create_contact_comment`,
          method: "POST",
          credentials: "include",
          body: json,
        };
      },
      invalidatesTags: [{ type: 'ContactComments' }],
    }),
    getContactComments: builder.query({
      query: (id) => {
        return {
          url: `contacts/${id}/comments`,
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "ContactComments" }];
      },
    }),
    getCompanyComments: builder.query({
      query: (id) => {
        return {
          url: `companies/${id}/comments`,
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "ContactComments" }];
      },
    }),
    getContactDocuments: builder.query({
      query: (id) => {
        return {
          url: `contacts/${id}/documents`,
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "Documents" }];
      },
    }),
    getCompanyDocuments: builder.query({
      query: (id) => {
        return {
          url: `companies/${id}/documents`,
          credentials: "include",
        };
      },
      providesTags: () => {
        return [{ type: "Documents" }];
      },
    }),
    getContractDocuments: builder.query({
      query: (id) => {
        return {
          url: `contracts/${id}/documents`,
          credentials: "include",
        };
      },
      providesTags: (documents) => {
        return [{ type: "Documents" }];
      },
    }),
    getDocument: builder.query({
      query: (id) => {
        return {
          url: `documents/${id}`,
          credentials: "include",
        };
      },
      providesTags: (results, error, id) => [{ type: "Documents", id }],
    }),
    tagDocument: builder.mutation({
      query: (json) => {
        return {
          url: "tag",
          method: "POST",
          credentials: "include",
          body: json,
        };
      },
      invalidatesTags: [{ type: 'Documents' }],
    }),
    saveDocument: builder.mutation({
      query: ({ imageId, contractId, contactId, companyId }) => {
        return {
          url: "documents",
          method: "POST",
          credentials: "include",
          body: {
            image_id: imageId,
            contract_id: contractId,
            contact_id: contactId,
            company_id: companyId,
          }
        };
      },
      invalidatesTags: [ "Documents" ],
    }),
    createUploadToken: builder.mutation({
      query: () => {
        return {
          url: "upload",
          method: "POST",
          credentials: "include",
        };
      },
    }),
    submitPaymentRequest: builder.mutation({
      query: (id) => {
        return {
          url: `payreq`,
          method: "POST",
          body: { id: id },
          credentials: "include",
        };
      },
      invalidatesTags: ["Contract", "Contracts"],
    }),
    getCurrentPlayer: builder.query({
      query: (email) => {
        return {
          url: "player",
          credentials: "include",
        };
      },
      providesTags: (user) => {
        return [{ type: "Player", id: user }];
      },
    }),
    tokenLogin: builder.mutation({
      query: (token) => {
        return {
          url: "token",
          method: "POST",
          body: { login_token: token },
          credentials: "include",
        };
      },
      invalidatesTags: ["Player", "Contract"],
    }),
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "login",
          method: "POST",
          body: credentials,
          credentials: "include",
        };
      },
      invalidatesTags: ["Player", "Contract"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
        credentials: "include",
      }),
      invalidatesTags: ["Player"],
    }),
  }),
});

export const {
  useGetCallQuery,
  useGetCallListQuery,
  useGetTasksQuery,
  useGetContactQuery,
  useGetContactsQuery,
  useGetWeeklyStatsQuery,
  useGetContractQuery,
  useGetOpenContractsQuery,
  useGetOpenContractsWithDocsQuery,
  useGetCompanyCommentsQuery,
  useGetContactCommentsQuery,
  useCreateSmsThreadMutation,
  useCreateContactCommentMutation,
  useGetContactDocumentsQuery,
  useGetCompanyDocumentsQuery,
  useGetContractDocumentsQuery,
  useGetDocumentQuery,
  useTagDocumentMutation,
  useSaveDocumentMutation,
  useCreateUploadTokenMutation,
  useGetCurrentPlayerQuery,
  useSubmitPaymentRequestMutation,
  useTokenLoginMutation,
  useLoginMutation,
  useLogoutMutation } = bulkbookApi;
