import { Fragment } from "react";
import Spinner from '../spinner';
import {
  useGetContractDocumentsQuery,
  useSubmitPaymentRequestMutation,
} from "../../services/bulkbookApi";

export default function PaymentBar({ contract }) {
  const { data, error, isLoading } = useGetContractDocumentsQuery(contract.id);
  
  return (
    <div style={styles.container}>
      { error ?
        <div className="error">Error retrieving payment data</div> : null }
      { isLoading ? <Spinner size="20" /> : null }
      { data && contract.status === "closed" ?
        <div style={styles.pending}>Payment closed</div> : null}
      { data && contract.status === "pending" ?
        <div style={styles.pending}>Payment requested</div> : null}
      { data && data.length && contract.status === "open" ? 
        <RequestPaymentButton contractId={contract.id} /> : null }
    </div>
  );
}

function RequestPaymentButton({contractId}) {
  const [submitPayReq, { error, isLoading }] = useSubmitPaymentRequestMutation();

  return (
    <Fragment>
      { isLoading ? <Spinner size="20" /> : null }
      { error ? <div className="error">Error saving contract</div> : null }
      { !isLoading && !error ?
        <button style={styles.button} onClick={() => submitPayReq(contractId) }>
          Request payment
        </button> : null }
    </Fragment>
  )
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "right",
    backgroundColor: "white",
    borderTop: "1px solid #D3D3D3",
  },
  pending: {
    padding: 10,
  },
  button: {
    margin: 10,
    backgroundColor: "#55efc4",
  }
}
