import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/esm/Container";
import DocUploader from "../DocUploader";
import Spinner from '../spinner';
import {
  useGetDocumentQuery,
  useGetCompanyDocumentsQuery,
  useTagDocumentMutation,
} from "../../services/bulkbookApi";

const root = "https://imagedelivery.net/edgsOVLLonMQi4a5Ix9OvQ"

export function ContactDocuments({ cid, mid }) {
  const { data, error, isLoading } = useGetCompanyDocumentsQuery(mid);

  return (
    <ListGroup>
      {error ? <div className="error">Error loading data</div> : null}
      { isLoading ? <Spinner size="20" /> : null }
      { data ? data.map((d) => {
        const expired = d.tags && d.tags.expiry && 
          (new Date(d.tags.expiry)).getTime() < (new Date()).getTime();    
        const docType = d.tags && d.tags.type ? d.tags.type : '';
        const year = new Date().getFullYear();
        const url = `${root}/${d.image_id}/public`;
        return <ListGroup.Item as="li" key={d.image_id}>
          <Link to={'docs/' + d.image_id}>
            <div className="d-inline pe-2">
              <img src={url} width={26} height={33}/>
            </div>
            {docType ? docType : <Badge bg="danger">untagged</Badge>}&nbsp;
            {expired && docType === "insurance" ?
              <Badge bg="danger">{d.tags.expiry}</Badge> : null }
            {!expired && docType === "insurance" ?
              <Badge>{d.tags.expiry}</Badge> : null }
            <div className="d-inline float-end fs-6 fw-light" title="uploaded date">
              <small>{d.created.split('T')[0].replace(`${year}-`, '')}</small>
            </div>
          </Link>
        </ListGroup.Item>}) : null }
      <br/>
      <DocUploader contactId={cid} companyId={mid} />
    </ListGroup>
  );
}

function ContactDocFull({ imageId }) {
  const url = `${root}/${imageId}/public`;
  const style = {
    padding: 10,
    backgroundColor: "#D3D3D3",
  };
  return (
    <div style={{margin: 10}} className="page-break">
      <a href={url} target="_blank">
        <img width="100%" src={url} style={style} className="page-break" />
      </a>
    </div>
  );
}

export function ContactDocumentsFull({ cid, mid }) {
  const { data, error, isLoading } = useGetCompanyDocumentsQuery(mid);

  return (
    <div>
        <div className="fullsize-image-container" style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        marginTop: 10,
        minHeight: 20,
        backgroundColor: "white"}}>
        {error ? <div className="error">Error loading data</div> : null}
        { isLoading ? <Spinner size="20" /> : null }
        { data ? data.map((d) => 
          <ContactDocFull key={d.image_id} imageId={d.image_id} />) : null }
      </div>
    </div>
  );
}

export function ContactDocumentModal({contactId, documentId}) {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDocumentQuery(documentId);
  const imageId = data && data.length ? data[0].image_id : null;
  const tags = data && data.length ? data[0].tags : null;

  return (
    <Modal show={true}
      backdrop="static"
      keyboard={false}
      size="lg">

      <Container>
        <Row>
          <Col>
            <Link className="float-end" Link to="#" onClick={() => navigate(-1)}>
                <i className="bi bi-x-lg"></i>
              </Link>
          </Col>
        </Row>
        <Row>
            {error ? <div className="error">Error loading data</div> : null}
            { isLoading ? <Spinner /> : null }
        </Row>
        {data ? <Tags imageId={imageId} tags={tags} /> : null}
      </Container>
      {data ? <img src={root + '/' + documentId + '/public'} /> : null}
    </Modal>
  )
}

function Tags({imageId, tags}) {
  const [newTags, setNewTags] = useState(tags || {});
  const [editable, setEditable] = useState(false);
  const [
    updatePost,
    {
      isLoading: isUpdating,
      error: errorUpdating,
      isSuccess: updated,
      reset: resetUpdate,
    },
  ] = useTagDocumentMutation();

  if (updated && editable) {
    setEditable(false);
    resetUpdate();
  }

  const click = () => {
    updatePost({...newTags, image_id: imageId});
  }

  const TagType = ({type: currentType}) => {
    return (
      <Form.Select value={currentType} 
        onChange={(e) => setNewTags({type: e.target.value})}>
        <option></option>
        <option value="insurance">Insurance</option>
        <option value="profile">Profile</option>
        <option value="contract">Contract</option>
        <option value="w9">W9</option>
        <option value="authority">Authority</option>
        <option value="noa">NOA</option>
        <option value="other">Other</option>
    </Form.Select>
    );
  };

  if (tags && !editable) {
    const expired = tags.expiry && 
      (new Date(tags.expiry)).getTime() < (new Date()).getTime();

    return (
      <div className="p-2 d-flex align-items-center">
        <Badge className="fs-5 mx-1" size="lg">{tags.type}</Badge>
        {tags.type === "insurance" && expired ?
          <Badge bg="danger">{tags.expiry}</Badge> : null }
        {tags.type === "insurance" && !expired ?
          <Badge>{tags.expiry}</Badge> : null }
        <Button variant="light" onClick={()=> setEditable(true)} size="sm">
            edit tags
        </Button>
      </div>
    );
  }

  return (
    <Row className="p-2">
      <Col>
        {newTags ? <TagType type={newTags.type}/> : null }
      </Col>
      {newTags.type === "insurance" ? 
        <Col>
          <Form.Control type="date" value={newTags.expiry} onChange={
            (e) => setNewTags({...newTags, expiry: e.target.value})} />
        </Col>: null}
      <Col >
        {isUpdating ?
          <Spinner size={42} /> :
          <Button onClick={click} variant="primary">Save</Button>}
        {errorUpdating ? <Badge bg="danger">Error saving</Badge> : null}
        {isUpdating ? null :
          <Button variant="light" onClick={()=> setEditable(false)} size="sm">
            cancel
          </Button>}
      </Col>
    </Row>
  )
}
