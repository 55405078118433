import "./spinner.css";

// Spinner from https://loading.io/css/

function Spinner({ className, size, message, variant }) {
  const cl = className ? `lds-ring ${className}` : "lds-ring";
  const ringHeight = size ?? 80;
  const ringWidth = ringHeight;
  const outerDivStyle = { height: ringHeight, width: ringWidth };
  const innerDivBorderWidth = ringHeight / 10;
  const borderColor = variant
    ? "#99c0ea transparent transparent transparent"
    : "#0062cc transparent transparent transparent";
  const innerDivStyle = {
    height: ringHeight - innerDivBorderWidth * 2,
    width: ringWidth - innerDivBorderWidth * 2,
    margin: innerDivBorderWidth,
    borderWidth: innerDivBorderWidth,
    borderColor
  };
  return (
    <div className="d-inline-flex align-middle">
      <div className={cl} role="status" style={outerDivStyle}>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
        <div style={innerDivStyle}></div>
      </div>
      <div className="ms-1" style={{ margin: innerDivBorderWidth }}>
        {message}
      </div>
    </div>
  );
}

export default Spinner;
