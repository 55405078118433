import { Link, useSearchParams  } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Spinner from "../spinner";
import { RequirePlayer } from "../auth";
import ContactSearchBar from "./ContactSearchBar";
import {
  useGetContactsQuery,
} from "../../services/bulkbookApi";

export default function ContactScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q', '');
  const { data, error, isLoading } = useGetContactsQuery(q);

  return (
    <RequirePlayer>
      <ContactSearchBar />
      { q ? <div style={{padding: 5, backgroundColor: 'white'}}>
        <strong>searching for:</strong> {q}
        <small> <Link to="/contacts">clear</Link></small></div>
        : null }
      { error ? <div className="error">Error loading data</div> : null }
      { isLoading ? <Spinner /> : null }
      { !error && data ? <ContactTable rows={data} /> : null }
    </RequirePlayer>
  )
}

function ContactTable({ rows }) {
  return (
    <Table striped hover>
      <tbody>
        {rows.map((r) => <ContactTableRow data={r} key={r.id} />)}
      </tbody>
    </Table>
  )
}

function ContactTableRow({ data }) {
  const Td = ({id, title, children}) => <td title={title} key={title+id}>
    <Link to={'/contacts/' + id}>{children}</Link>
  </td>;

  return (
    <tr>
      <Td title="Contact id" id={data.id}>C{data.id}</Td>
      <Td title="Name" id={data.id}>{data.first} {data.last}</Td>
      <Td title="Location" id={data.id}>{data.city} {data.state}</Td>
      <Td title="Company" id={data.id}>{data.company_name}</Td>
    </tr>
  )
}
