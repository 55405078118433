import { Link } from "react-router-dom";
import { TaskBar } from "./task"
import usePlayer from "./auth/usePlayer";

function NavBar() {
  const { player, logout } = usePlayer();
  const defaultURL = player && player.role == "billing" ? "/loads" : "/contacts";
  return (
    <div style={styles.container}>
      <div style={styles.logo}>
        <Link style={styles.logoText} to={defaultURL}>
          <span style={styles.logoBulk}>bulk</span>
          <span style={styles.logoBook}>book</span>
        </Link>
      </div>
      <div style={{ float: "right", padding: 10 }}>
        <TaskBar />
        <span style={{ color: "#636e72" }}>
          {player ? player.email : "" }&nbsp;&nbsp;
        </span>
        {player ? <button onClick={() => logout()}>Logout</button> : ""}
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
}

const styles = {
  logoBulk: {
    color: "#2d3436",
  },
  logoBook: {
    color: "#636e72",
  },
  logo: {
    fontSize: 24,
    fontWeight: "bold",
    float: "left",
    padding: 10,
    margin: 0,
    color: "white",
  },
  container: {
    backgroundColor: "#fdcb6e",
  }
};
export default NavBar;
